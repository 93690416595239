<!--
 * @Author: wxb
 * @Date: 2021-07-07 22:56:59
 * @LastEditTime: 2021-08-10 22:53:17
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Document\libaryDetail.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='4'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/libary'}">搜文库</el-breadcrumb-item>
        <el-breadcrumb-item>文库详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="libary-content"
           v-show="canSee">
        <div class="panel">
          <div class="panel-title"
               v-show="searchForm.nodeType==='1'">{{libaryInfo.bzgfwdzybzh}}{{libaryInfo.bzgfwdzybzgfzwmc}}</div>
          <div class="panel-title"
               v-show="searchForm.nodeType==='2'">{{libaryInfo.bztjtjbh}}{{libaryInfo.bztjtjmc}}</div>
          <div class="panel-title"
               v-show="searchForm.nodeType==='3'">{{libaryInfo.lawregusbt}}</div>
          <div class="panel-title"
               v-show="searchForm.nodeType==='4'">{{libaryInfo.textmmc}}</div>
          <div class="panel-info">
            <div class="panel-follow"
                 :class="{followed:libaryInfo.action==='add' }"
                 @click="handleCollect">
              <svg class="icon"
                   aria-hidden="true">
                <use :xlink:href="libaryInfo.action | formatIcon"></use>
              </svg>收藏
            </div>
          </div>
          <div class="panel-content"
               v-show="searchForm.nodeType==='1'">
            <div class="fieldlist">
              <div class="fielditem">
                <span class="fielditem-title">备案号：</span>
                <span class="fielditem-value">{{libaryInfo.bzgfwdzy}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">标准分类：</span>
                <span class="fielditem-value">{{libaryInfo.bzgfwdzybasicclassifyname}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">代替标准号：</span>
                <span class="fielditem-value">{{libaryInfo.bzgfwdzydtbzh}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">被代替标准号：</span>
                <span class="fielditem-value">{{libaryInfo.bzgfwdzybdtbzh}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">备案日期：</span>
                <span class="fielditem-value">{{libaryInfo.bzgfwdzybarq}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">发布日期：</span>
                <span class="fielditem-value">{{libaryInfo.bzgfwdzyfbrq}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">实施日期：</span>
                <span class="fielditem-value">{{libaryInfo.bzgfwdzyssrq}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">废止日期：</span>
                <span class="fielditem-value">{{libaryInfo.bzgfwdzyfzrq}}</span>
              </div>
            </div>
          </div>
          <div class="panel-content"
               v-show="searchForm.nodeType==='2'">
            <div class="fieldlist">
              <div class="fielditem">
                <span class="fielditem-title">图集类型：</span>
                <span class="fielditem-value">{{libaryInfo.bzgfwdzybasicclassifyname}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">专业分类：</span>
                <span class="fielditem-value">{{libaryInfo.bztjzyflmc}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">主编单位：</span>
                <span class="fielditem-value">{{libaryInfo.bztjzbdw}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">发布日期：</span>
                <span class="fielditem-value">{{libaryInfo.bztjfbrq}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">实施日期：</span>
                <span class="fielditem-value">{{libaryInfo.bztjssrq}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">废止日期：</span>
                <span class="fielditem-value">{{libaryInfo.bztjfzrq}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">代替标准号：</span>
                <span class="fielditem-value">{{libaryInfo.bztjtdbzh}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">被代替标准号：</span>
                <span class="fielditem-value">{{libaryInfo.bztjbdtbzh}}</span>
              </div>

            </div>
            <div class="field-item">
              <div class="field-item-title">简介：</div>
              <div class="field-item-value">{{libaryInfo.bztjjj}}</div>
            </div>
          </div>
          <div class="panel-content"
               v-show="searchForm.nodeType==='3'">
            <div class="fieldlist">
              <div class="fielditem">
                <span class="fielditem-title">文号：</span>
                <span class="fielditem-value">{{libaryInfo.lawreguswh}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">发布日期：</span>
                <span class="fielditem-value">{{libaryInfo.lawregusfbrq}}</span>
              </div>
              <div class="fielditem">
                <span class="fielditem-title">实施日期：</span>
                <span class="fielditem-value">{{libaryInfo.lawregusssrq}}</span>
              </div>
            </div>
            <div class="field-item">
              <div class="field-item-title">全文：</div>
              <div class="field-item-value">
                <div v-html="libaryInfo.lawregusbz"></div>
              </div>
            </div>
          </div>
          <div class="panel-content"
               v-show="searchForm.nodeType==='4'">
            <div class="fieldlist">
              <div class="fielditem">
                <span class="fielditem-title">厂家名称：</span>
                <span class="fielditem-value">{{libaryInfo.textmgsmc}}</span>
              </div>
            </div>
            <div class="field-item">
              <div class="field-item-title">描述：</div>
              <div class="field-item-value">{{libaryInfo.textmms}}</div>
            </div>
          </div>
        </div>

        <div class="file-panel">
          <div class="file-panel-title">资源列表</div>
          <div class="file-panel-content">
            <el-table :data="fileList"
                      border
                      style="width: 100%">
              <template slot="empty">
                <el-empty description="哎呀，未查询到相关信息"></el-empty>
              </template>
              <el-table-column label="序号"
                               width="50"
                               align="center">
                <template slot-scope="scope">{{scope.$index+1}}</template>
              </el-table-column>
              <el-table-column prop="fileName"
                               label="名称"
                               align="center">
                <template slot-scope="scope">
                  <el-button type="text"
                             size="small"
                             @click="handleClickLook(scope.row.fileID)"
                             :title="scope.row.fileName">{{scope.row.fileName}}</el-button>
                </template>
              </el-table-column>
              <!-- <el-table-column align="center"
                         label="操作"
                         width="120">
          <template slot-scope="scope">
            <el-button @click="handleClickLook(scope.row)"
                       type="text"
                       size="small">查看</el-button>
            <el-button @click="handleClickDownload(scope.row)"
                       type="text"
                       size="small">下载</el-button>
          </template>
        </el-table-column> -->
            </el-table>
          </div>
        </div>
      </div>
      <viporlogin bgStr="5"
                  :type="viporlogin"
                  v-show="!canSee"></viporlogin>
    </div>
    <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    <footerNav></footerNav>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import viporlogin from '@/components/viporlogin'
import dialogviporlogin from '@/components/dialogviporlogin'
import { mapGetters } from 'vuex'
import { fileDetails } from '@/api/document'
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { collection } from '@/api/collect'
export default {
  name: 'libaryDetail',
  components: {
    headerNav,
    footerNav,
    viporlogin,
    dialogviporlogin
  },
  data() {
    return {
      viporlogin: 'login',
      canSee: true,
      searchForm: {
        id: '',
        nodeType: ''
      },
      libaryInfo: {},
      fileList: [{}]
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    // 鉴权
    authCheck() {
      const authObj = authenticate('libaryDetail', this.memberType)
      if (authObj.flag) {
        // 初始化页面
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    initPage() {
      fileDetails(this.searchForm).then(res => {
        if (res.ok) {
          if (this.searchForm.nodeType === '1') {
            this.libaryInfo = res.bzgfInfo
          } else if (this.searchForm.nodeType === '2') {
            this.libaryInfo = res.bgtjInfo
          } else if (this.searchForm.nodeType === '3') {
            this.libaryInfo = res.flfgInfo
            this.libaryInfo.lawregusbz = res.flfgInfo.lawregusbz.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&apos;/g, '\'')
          } else if (this.searchForm.nodeType === '4') {
            this.libaryInfo = res.fileInfo
          }
          this.libaryInfo.action = res.action
          this.fileList = res.fileList
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 预览
    handleClickLook(filekey) {
      const routeData = this.$router.resolve({
        name: 'preview',
        params: {
          filekey
        }
      })
      window.open(routeData.href, '_blank')
    },
    // 收藏文库
    handleCollect() {
      if (!getToken()) {
        this.$refs.dialogviporlogin.handleShow('login')
        return false
      }
      const params = {
        collectionitemId: this.searchForm.id,
        collectionType: 'library'
      }

      // 根据不同类型的资源传递不同的参数
      if (this.searchForm.nodeType === '1') {
        params.collectionSonType = 'strandard'
        params.collectionitemName = this.libaryInfo.bzgfwdzybzgfzwmc
      } else if (this.searchForm.nodeType === '2') {
        params.collectionSonType = 'atlas'
        params.collectionitemName = this.libaryInfo.bztjtjmc
      } else if (this.searchForm.nodeType === '3') {
        params.collectionSonType = 'lays'
        params.collectionitemName = this.libaryInfo.lawregusbt
      } else if (this.searchForm.nodeType === '4') {
        params.collectionSonType = 'data'
        params.collectionitemName = this.libaryInfo.textmmc
      }

      if (this.libaryInfo.action === 'cancle') {
        params.action = 'add'
        this.handleCollectEvent(params)
      } else if (this.libaryInfo.action === 'add') {
        params.action = 'cancle'
        this.$confirm('确认取消文库收藏?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleCollectEvent(params)
        })
      }
    },
    handleCollectEvent(params) {
      collection(params).then(res => {
        if (res.ok) {
          this.libaryInfo.action = params.action
          this.$forceUpdate()
          if (params.action === 'add') {
            this.$message({
              message: '收藏成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: '取消收藏成功',
              type: 'success'
            })
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      }).catch(err => { console.log(err) })
    }
  },
  mounted() {
    const { id, type } = this.$route.params
    this.searchForm.id = id
    this.searchForm.nodeType = type
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  },
  filters: {
    formatIcon(action) {
      if (action === 'add') {
        return '#icon-shoucang2'
      } else {
        return '#icon-shoucang1'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.panel {
  margin-top: 2rem;
  padding: 1rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  &-title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
    font-weight: 500;
    text-align: center;
  }

  &-info {
    display: flex;
    width: 800px;
    margin: 10px auto;
    justify-content: flex-end;
    font-size: 14px;

    .panel-follow {
      cursor: pointer;

      &.followed {
        .icon {
          color: #ec2929;
        }
      }
    }
  }

  &-content {
    padding: 1rem 0;

    .fieldlist {
      display: flex;
      flex-wrap: wrap;

      .fielditem {
        width: 47.3rem;
        padding: 1rem 0;
        font-size: 14px;
        user-select: none;
        &-title {
          color: #717171;
          width: 10rem;
          text-align: right;
          display: inline-block;
          overflow: hidden;
        }

        &-value {
          width: 36.3rem;
          font-weight: 500;
          display: inline-block;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
      }
    }

    .field-item {
      padding: 1rem 0;
      font-size: 14px;
      user-select: none;
      display: flex;
      &-title {
        color: #717171;
        width: 10rem;
        text-align: right;
        display: inline-block;
      }

      &-value {
        flex: 1;
        font-weight: 500;
        display: inline-block;
      }
    }
  }
}

.file-panel {
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-height: 50rem;
  &-title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
    font-weight: 500;
    border-bottom: 1px solid #ebeef5;
    padding: 1.5rem;
  }

  &-content {
    padding: 1.5rem;
  }
}
</style>
